<template>
	<div
		class="block-background"
		:class="{
			'block-background--fixed': isFixed,
			'block-background--animated-gradient': type === 'gradient' && gradient?.isAnimated
		}"
	>
		<img
			v-if="type === 'image' && src"
			:key="src"
			:alt="alt"
			:src="src"
			:srcset="srcset"
			width="100vw"
			sizes="(max-width: 500px) 800px, 100vw"
			:loading="isEager ? 'eager' : 'lazy'"
			class="block-background__image"
			:class="{ 'block-background__image--fixed': isFixed }"
			:style="mobilePreviewStyles"
		>
		<div
			v-if="isBackgroundOverlayShown"
			class="block-background__overlay"
			:class="{ 'block-background__overlay--fixed': isFixed }"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	inject,
	ref,
} from 'vue';
import {
	SiteBackgroundGradient,
	SiteBackgroundType,
} from '@hostinger/builder-schema-validator';
import { getGradientCss } from '@zyro-inc/site-modules/utils/cssUtils';

interface Props {
	type: SiteBackgroundType;
	alt?: string;
	overlayOpacity?: string;
	src?: string;
	srcset?: string;
	isEager?: boolean;
	gradient?: SiteBackgroundGradient | null;
	color?: string | null;
	isFixed?: boolean;
	isInPreviewMode?: boolean
	isMobile?: boolean
	isOpacityDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	type: 'color',
	isInPreviewMode: false,
	isMobile: false,
	isOpacityDisabled: false,
});

const backgroundColor = computed(() => {
	if (props.type === 'gradient' && props.gradient) {
		return getGradientCss(props.gradient);
	}

	if (props.isOpacityDisabled) {
		return props.type === 'color' ? props.color : 'rgb(255, 255, 255)';
	}

	return props.type === 'color' ? props.color : 'transparent';
});
const gradientBackgroundPosition = computed(() => {
	const angle = (props.gradient?.angle || 0) + 90;
	const rad = (angle * Math.PI) / 180;

	const xStart = `${Math.round(50 - 50 * Math.cos(rad))}%`;
	const yStart = `${Math.round(50 - 50 * Math.sin(rad))}%`;
	const xEnd = `${Math.round(50 + 50 * Math.cos(rad))}%`;
	const yEnd = `${Math.round(50 + 50 * Math.sin(rad))}%`;

	return {
		xStart,
		yStart,
		xEnd,
		yEnd,
	};
});

const isBackgroundOverlayShown = computed(() => props.type === 'image' && !props.isOpacityDisabled && props.overlayOpacity);
const backgroundOverlayOpacity = computed(() => (props.type === 'image' && !props.isOpacityDisabled && props.overlayOpacity ? props.overlayOpacity : 0));

const mobileFrame = ref<HTMLDivElement | undefined>(inject<HTMLDivElement>('mobileFrame'));

const mobilePreviewStyles = computed(() => {
	if (props.isInPreviewMode && props.isMobile && props.isFixed && !!mobileFrame.value) {
		const rect = mobileFrame.value.getBoundingClientRect();

		return {
			height: `${rect.height}px`,
			width: `${rect.width}px`,
			top: `${rect.top}px`,
			left: '50%',
			transform: 'translate(-50%, 0)',
		};
	}

	return {};
});

</script>

<style lang="scss">
.block-background {
	position: absolute;
	top: 0;
	right: 0;

  // -0.5px from bottom fixes subpixel gap on window resize which is visible on dark colors
	bottom: -0.5px;
	left: 0;
	z-index: $z-index-site-engine-block-background;
	overflow: hidden;
	background: v-bind(backgroundColor);

	&--animated-gradient {
    animation: animate-gradient 25s ease infinite;
		background-size: 400% 400%;

		@keyframes animate-gradient {
			0%{background-position: v-bind('gradientBackgroundPosition.xStart') v-bind('gradientBackgroundPosition.yStart')}
			50%{background-position: v-bind('gradientBackgroundPosition.xEnd') v-bind('gradientBackgroundPosition.xEnd')}
			100%{background-position: v-bind('gradientBackgroundPosition.xStart') v-bind('gradientBackgroundPosition.yStart')}
		}

		@keyframes animate-gradient {
			0%{background-position: v-bind('gradientBackgroundPosition.xStart') v-bind('gradientBackgroundPosition.yStart')}
			50%{background-position: v-bind('gradientBackgroundPosition.xEnd') v-bind('gradientBackgroundPosition.xEnd')}
			100%{background-position: v-bind('gradientBackgroundPosition.xStart') v-bind('gradientBackgroundPosition.yStart')}
		}

		@keyframes animate-gradient {
			0%{background-position: v-bind('gradientBackgroundPosition.xStart') v-bind('gradientBackgroundPosition.yStart')}
			50%{background-position: v-bind('gradientBackgroundPosition.xEnd') v-bind('gradientBackgroundPosition.xEnd')}
			100%{background-position: v-bind('gradientBackgroundPosition.xStart') v-bind('gradientBackgroundPosition.yStart')}
		}
	}

	// Height transition is needed to fix background images zooming on scroll on mobile
	// because of disappearing/appearing browser bottom navigation bar
	transition: background-color 0.3s ease-in-out, height 1ms linear 999s;

	// clip-path is needed for firefox, safari browsers without it fixed background covers all the page
	// -1px from bottom fixes 1px gap in chrome browsers
	&--fixed {
		bottom: -1px;
		clip-path: inset(0);
		backface-visibility: hidden;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		display: block;
		width: 100%;

		// By using 101% instead of 100%, the bug that caused a white gap to appear after an image was fixed.
		// This gap occurred because the uploaded image had a smaller size and different aspect ratio compared to the section.
		height: 101%;
		object-fit: cover;

		&--fixed {
			position: fixed;
			height: 100vh;
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: $color-dark;
		opacity: v-bind(backgroundOverlayOpacity);

		&--fixed {
			position: fixed;
			height: 100vh;
		}
	}
}
</style>
